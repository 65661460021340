export default function (x: any, decimalPlaces?: number) {
  return x
    ? x.toLocaleString(
        'en-US',
        decimalPlaces !== undefined
          ? {
              minimumFractionDigits: decimalPlaces,
            }
          : {},
      )
    : 0
}
